.App {
  text-align: center;
  background-image: url('assets/javasite/logo.png');
  background-position: center;
  background-repeat:  no-repeat;
  min-height: 100vh;
  animation-name: logo;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  display: flex;
  flex-direction:column;
}

.App-header {
  background-color: #282c34;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'PerfectDark';
}

main {
  flex-grow: 1;
  padding: 10px;

}

.thumbnails {
  width: 150px;
  height: 150px;
  display: inline-block;
  margin: 20px;
  border-radius: 10px;
  opacity: 1;
}

.slides {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.each-slide {
  height: 500px;
}

footer {
  padding: 10px;
}

@keyframes logo {
  0% {
    background-image: url('assets/javasite/logo.png');
  }
  25% {
    background-image: url('assets/javasite/logoblue.png');
  }
  50% {
    background-image: url('assets/javasite/logogreen.png');
  }
  75% {
    background-image: url('assets/javasite/logored.png');
  }
  100% {
    background-image: url('assets/javasite/logo.png');
  }
}

#bottombar {
  position: relative;
  min-height: 55px;
  background-image: url('assets/javasite/bottombar.jpg');
  background-repeat: repeat-x;
  z-index: 2;
  border-radius: 13px 13px 13px 13px;
  -moz-border-radius: 13px 13px 13px 13px;
  -webkit-border-radius: 13px 13px 13px 13px;
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1rem;
  color: rgb(165, 133, 133);
  display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding-top: 10px;
    background-color: black;
}

#bottomlinks span a, #bottomlinks span a:link, #bottomlinks span a:hover, #bottomlinks span a:visited, #bottomlinks span a:active {
  color: #FFFFFF;
}

#about1 {display: block;}

#about1
		{
			width: 100%;
			height:auto;
			border:none;
      margin-bottom: 100px;
      margin-top: 50px;
			
}

.aboutbox {
			width:775px;
			height:638px;
			display:block;
			border:none;
			position:absolute;
			z-index: 100;
			left: 0px;
			top: 0px;
				padding: 0;
	margin: 0;
}
.aboutbox .aboutItem {
				display: none;
				position:absolute;
				top:0;
				left:0;
				border:none;
}

#aboutbutton {
	display: inline-block;
	margin-left: 20px;
	margin-right: 20px;
	z-index:2;
	width: 74px;
	height: 63px;
	background-image: url('./assets/javasite/images/DraZticSiteJava_14.jpg');
	cursor: pointer;
}
#aboutbutton:hover {
	background-image: url('./assets/javasite/images/rollover_14.jpg');
}

#abouttext {
	font-size: 14px;
	color: #FFF;
}


.blackbox {
  max-width: 800px;
  width: auto;
  height: auto;
  position: relative;
  z-index: 9;
  left: 0px;
  top: 0px;
  padding: 30px;
  padding-bottom: 100px;
  background: #4c4c4c;
  background: -moz-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
  background: -webkit-linear-gradient(top, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
  background: linear-gradient(to bottom, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=0 );
}



#title1, #title2, #title3, #title4, #title5 {
  font-family: 'PerfectDark';
}
#title4 {
  width: 100%;
  height: 50px;
  font-size: 24px;
  color: #FFF;
}

.MuiList-root a:link, .MuiList-root a:visited, .MuiList-root a:hover, .MuiList-root a:active, .MuiList-root a {
  text-decoration: none;
  color: inherit;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
   
  .thumbnails {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 10px;
    border-radius: 5px;
    opacity: 1;
  }

  }